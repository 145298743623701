<template>
  <div
    :class="passportStyle"
    v-if="localValue.passportEntities && localValue.passportEntities.length"
  >
    <div v-if="isMap">
      <PassportMapObjectItem
        v-for="(item, index) in localValue.passportEntities"
        :key="index +'-'+ item.id"
        :value="item"
        :scenario="scenario"
      />
    </div>

    <div
      v-else
      style="grid-gap: 15px"
      class="d-flex flex-wrap"
    >
      <BaseCardStatistic
        v-if="objectsByType"
        v-for="(value, key) in objectsByType"
        :key="key"
        :statistic="value"
        :objects-schema="getCurrentObject(key)"
        @click="handlePassportObjectItem(key)"
      />
    </div>
    <BasePreloader
      class="d-flex justify-center"
      style="width: 100%"
      v-if="loading"
    />
  </div>

  <div v-else>
    <BaseAlert
      type="info"
      :text="$t('message.noObjects')"
    />
  </div>
</template>

<script>
import BaseAlert from '@/components/base/UI/BaseAlert'
import PassportMapObjectItem
  from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/passportObjectsList/PassportMapObjectItem'
import BaseCardStatistic from '@/components/views/account/dashboard/passports/BaseCardStatistic'
import dashboard from '@/components/mixins/dashboard'
import loading from '@/components/mixins/loading'
import BasePreloader from '@/components/base/UI/BasePreloader'

export default {
  name: 'PassportObjectsListItems',
  mixins: [dashboard, loading],
  components: {
    PassportMapObjectItem,
    BaseAlert,
    BaseCardStatistic,
    BasePreloader
  },
  props: {
    value: Object,
    scenario: String
  },
  data () {
    return {
      localValue: this.value,
      filter: '?filter[passport_id][]=' + this.value.id
    }
  },
  computed: {
    objectsByType () {
      return this.passports[this.value?.type]?.objects
    },
    isMap () { //todo: сделать миксин и везде отрефакторить. Условие очень часто повторяется
      return this.$route.name === 'map'
    },
    passportStyle () {
      if (this.isMap) {
        return ''
      } else {
        return 'd-flex justify-start flex-wrap'
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: true
    },
    localValue: {
      handler () {
        this.$emit('input', this.localValue)
      },
      deep: true
    },
    loading: {
      handler () {
        this.$emit('loading', this.loading)
      }
    }
  },
  methods: {
    handlePassportObjectItem (key) {
      this.$router.push({ name: 'passportObjectList', params: { entityId: key } })
    }
  }
}
</script>

<style scoped>

</style>
