<template>
  <BaseDialog
    :value="value"
    @input="handleClose"
    :label="title"
    is-full-screen
  >
    <template #customBtn>
      <BaseBtn
        :title="$t('base.done')"
        text
        color="primary"
        @click="handleConfirm"
      >
        {{ $t('base.done') }}
      </BaseBtn>
    </template>
    <template #content>
      <v-card-title v-if="cardTitle">{{ cardTitle }}</v-card-title>
      <slot
        name="table"
      ></slot>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'
import BaseBtn from '@/components/base/UI/BaseBtn'
import keyup from '@/components/mixins/keyup'

export default {
  name: 'BaseRelatedTable',
  components: { BaseBtn, BaseDialog },
  mixins: [keyup],
  props: {
    value: Boolean,
    title: String,
    cardTitle: String
  },
  methods: {
    handleConfirm () {
      this.$emit('input', false)
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
