<template>
  <div>
    <BaseCard
      v-if="detail"
      class="ma-3 dashboard-card"
    >
      <template #header>
        <v-card-text
          v-if="$store.getters['auth/getCurrentRole'] === 'unit' || $can('admin') || $can('manager') || !value.properties.id"
          class="d-flex justify-start align-center"
        >
          <VerificationBtn
            :can-verify="canVerify"
            :loading="verificationLoading"
            @click="handleVerificationSend"
          />
          <BaseAlert
            v-if="!value.properties.id"
            type="info"
            text="Перед добавлением объектов к паспорту сохраните его"
          />
          <BaseBtn
            v-else
            x-small
            v-show="$can('admin') || $can('manager')"
            :disabled="verificationLoading || !$can('manager') || $can('admin')"
            color="primary"
            icon="mdi-plus"
            @click="dialogObject=!dialogObject"
            :title="$t('message.addObject')"
          />
        </v-card-text>
        <v-divider v-if="$store.getters['auth/getCurrentRole'] === 'unit' || $can('admin') || !value.properties.id"></v-divider>
      </template>
      <template #content>
        <div class="text-h5 font-weight-bold text-break lh-normal mb-4">
          {{ detail.properties.object_name || 'Паспорт без названия' }}
          <v-card-subtitle
            v-if="detail.properties.region"
            class="pa-0 ma-0 text-heading-4"
          > {{ detail.properties.region.fullLabel }} <br>
            {{ formatValue('passport.type', Number(detail.properties.type)) }}
          </v-card-subtitle>
        </div>
        <InfoArea
          v-model="detail"
          class="mb-4 pl-3"
        />
        <PassportObjectsListItems
          class="pl-1"
          v-model="detail.properties"
          :scenario="scenario"
          @loading="verificationLoading = $event"
        />
        <div
          v-for="(item, key) in infoProperties"
          v-if="item"
          :key="key"
          class="subtitle-2 ma-0 text--darken-2 grey--text mt-4 lh-normal"
        >
          <b class="ma-0">{{ $t('passport.' + key) + ': ' }}</b>
          <p class="ma-0 mb-2">{{ item || '–' }} </p>
        </div>
        <ObjectList
          v-model="detail.properties.passportEntities"
          :dialog.sync="dialogObject"
          :name="value.properties.object_name"
          @done="$emit('done')"
        />
        <BaseDialog
          label="Верификация"
          text="Отправить данные паспорта на проверку?"
          @confirm="verificationInDialog"
          v-model="isDialogVerify"
          is-confirm-btn
          is-cancel-btn
          text-cancel="Закрыть"
          :max-width="450"
        />
      </template>
    </BaseCard>
    <BasePreloader v-else/>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BasePreloader from '@/components/base/UI/BasePreloader'
import BaseAlert from '@/components/base/UI/BaseAlert'
import ObjectList from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/ObjectList'
import BaseBtn from '@/components/base/UI/BaseBtn'
import PassportObjectsListItems
  from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/passportObjectsList/PassportObjectsListItems'
import BaseCard from '@/components/base/BaseCard'
import report from '@/components/mixins/report'
import VerificationBtn
  from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/VerificationBtn'
import verification from '@/components/mixins/verification'
import BaseDialog from '@/components/base/BaseDialog'
import PassportInfo from '@/components/views/account/map/detail/mapPassportDetail/PassportInfo'
import InfoArea from '@/components/views/account/map/detail/mapPassportDetail/passportInfo/InfoArea'
import _ from 'lodash'

export default {
  mixins: [formatters, report, verification],
  name: 'PassportObjectsList',
  components: {
    InfoArea,
    BaseCard,
    PassportObjectsListItems,
    BaseBtn,
    ObjectList,
    BaseAlert,
    BasePreloader,
    VerificationBtn,
    BaseDialog,
    PassportInfo
  },
  props: {
    value: Object,
    scenario: String
  },
  data () {
    return {
      dialogObject: false,
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true
    },
    detail: {
      handler () {
        this.$emit('input', this.detail)
      },
      deep: true
    }
  },
  computed: {
    infoProperties () { //создание объекта по указанным ключам
      const keys = ['land_function', 'executing_organization', 'owner', 'classification_code', 'classification_code_detail', 'using_mode', 'address', 'origin_executor']
      return _.pick(this.value.properties, keys)
    }
  }
}
</script>

<style scoped>
  .dashboard-card > /deep/ .block{
    background: #FAFAFA !important;
  }
</style>
