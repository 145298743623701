import { render, staticRenderFns } from "./PassportMapObjectItem.vue?vue&type=template&id=5ecbe4bc&scoped=true&"
import script from "./PassportMapObjectItem.vue?vue&type=script&lang=js&"
export * from "./PassportMapObjectItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecbe4bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemSubtitle})
