class Verification {
  constructor (props) {
    this.id = null
    this.organization_id = props.organization_id
    this.user_id = props.user_id
    this.status = 1 //todo: вынести статусы в константы
    this.passport_id = props.passport_id
  }
}

export default Verification
