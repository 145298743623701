<template>
  <!--todo: переписать проверку-->
  <tr @click="$route.name ==='constructor' ?  detail(item.id) : '' ">
    <td
      v-if="selectable"
      width="30"
    >
      <v-checkbox
        class="mt-0 pt-0"
        dense
        v-model="localSelected"
        @click.passive.stop=""
        hide-details
      />
    </td>
    <td>{{item.id}}</td>
    <td>{{item.entityName}}</td>
    <td class="align-center">
      <v-avatar
        class="mt-1 mb-1"
        width="30"
        min-width="30"
        height="30"
        :color="item.style.color"
      >
        <v-icon
          color="white"
          class="icon-avatar"
        >{{item.icon}}
        </v-icon>
      </v-avatar>
    </td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem'
}
</script>

<style scoped>

</style>
