import Verification from '@/components/structures/verification'

export default {
  data() {
    return {
      verificationLoading: false,
      isDialogVerify: false
    }
  },
  computed: {
    canVerify () {
      return !!(this.detail.properties.canVerify && this.detail.properties.id)
    }
  },
  methods: {
    async handleVerificationSend () {
      if (!this.verificationLoading) {
        const currentRole = this.$store.getters['auth/getCurrentRole'] === 'unit'
        if (currentRole) {
          this.isDialogVerify = true
        } else {
          await this.verificationSend()
        }
      }
    },
    async verificationInDialog() {
      await this.verificationSend()
      this.isDialogVerify = false
    },
    async verificationSend () {
      this.verificationLoading = true
      const profile = this.$store.getters['auth/getProfile']
      let response = await this.$store.dispatch('server/save', {
        url: '/verification/detail',
        data: {
          data: new Verification({
            user_id: profile.id,
            organization_id: profile.organization.id,
            passport_id: this.value.properties.id
          })
        }
      })
      if (response) {
        this.detail.properties.canVerify = false
      }
      this.verificationLoading = false
      return true
    }
  }
}
