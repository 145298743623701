<template>
  <BaseBtn
    v-if="canCreateVerification"
    color="secondary"
    icon="mdi-check"
    x-small
    :disabled="!canVerify"
    :title="title"
    @click="$emit('click')"
    :loading="loading"
  />
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  name: 'VerificationBtn',
  components: { BaseBtn },
  props: {
    mini: {
      type: Boolean,
      default: false
    },
    canVerify: Boolean,
    loading: Boolean
  },
  computed: {
    canCreateVerification () {
      return this.$store.getters['auth/getCurrentRole'] === 'unit'
    },
    title () {
      if (this.mini) {
        return ''
      }
      return this.canVerify ? this.$t('verification.send') : this.$t('verification.verifying')
    }
  }
}
</script>

<style scoped>

</style>
