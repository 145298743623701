<template>
  <BaseDetail
    class="base-detail"
    :is-loading="loading"
  >
    <template #header>
      <nobr/>
    </template>
    <template #content>
      <PassportObjectsList
        @done="$emit('save')"
        v-model="detail"
        :scenario="scenario"
      />
    </template>
  </BaseDetail>
</template>

<script>
import PassportObjectsList
  from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/PassportObjectsList'
import { deepEqual } from '@/components/utils/common'
import BaseDetail from '@/components/base/BaseDetail'
import loading from '@/components/mixins/loading'

export default {
  mixins: [loading],
  name: 'CommonInfo',
  components: { PassportObjectsList, BaseDetail },
  props: {
    value: Object,
    scenario: String
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        if (!deepEqual(this.value, this.detail)) {
          this.detail = this.value
        }
      },
      deep: true,
      immediate: true
    },
    detail: {
      handler () {
        this.$emit('input', this.detail)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .base-detail > /deep/ .content {
    background: #ffffff !important;
  }
</style>
