<template>
  <v-list-item class="rounded-1  ma-2">
    <v-list-item-subtitle><b>{{value.eavEntity.entityName}}</b></v-list-item-subtitle>
  </v-list-item>
</template>

<script>
export default {
  name: 'PassportMapObjectItem',
  props: {
    value: Object
  }
}
</script>

<style scoped>

</style>
