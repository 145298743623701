<template>
  <!--todo: рефактор, вынести таблицу в отдельный компонент, чтобы использовать ее с BaseRelatedTable и отдельно на карте -->
  <BaseRelatedTable
    v-if="$route.name !== 'map'"
    :value="dialog"
    @input="handleInput"
    @close="handleClose"
    :title="$t('message.addObject')"
    :card-title="'Объекты для паспорта: ' + (name ? name : '')"
  >
    <template #table>
      <BaseTable
        v-model="selectedItems"
        :headers="localHeaders"
        :items="items"
        :options.sync="localQuery.options"
        :loading="isLoading"
        :show-select="true"
        class="object-table"
      >
        <template #item="{item, isSelected}">
          <ListItem
            :is-selected="isSelected"
            selectable
            :item="item"
            :can-click="false"
            @input="handleAndEmitSelected($event)"
          />
        </template>
      </BaseTable>
    </template>
  </BaseRelatedTable>
  <!-- Блок для карты -->
  <div
    v-else
    class="d-flex flex-column wrapper-map-table"
  >
    <div>
      <BaseBtn
        color="primary"
        @click="handleInput"
        title="Готово"
        :loading="isLoading"
        class="d-flex justify-end ma-2"
      ></BaseBtn>
    </div>

    <BaseTable
      v-model="selectedItems"
      :headers="localHeaders"
      :items="items"
      :options.sync="localQuery.options"
      :loading="isLoading"
      :show-select="true"
      class="map-table"
    >
      <template #item="{item, isSelected}">
        <ListItem
          :is-selected="isSelected"
          selectable
          :item="item"
          :can-click="false"
          @input="handleAndEmitSelected($event)"
        />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import headers from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/objectList/headers'
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import ListItem from '@/components/views/account/constructor/list/ListItem.vue'
import BaseTable from '@/components/base/BaseTable'
import table from '@/components/mixins/table'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import PassportEntity from '@/components/structures/passportEntity'
import BaseBtn from '@/components/base/UI/BaseBtn'
import keyup from '@/components/mixins/keyup'

export default {
  mixins: [keyup, table, tableSelectedItems],
  name: 'ObjectList',
  components: { BaseBtn, BaseTable, ListItem, BaseRelatedTable },
  props: {
    dialog: Boolean,
    name: String
  },
  data () {
    return {
      //value - passportEntities: []
      localHeaders: headers
    }
  },
  computed: {
    controllerName () {
      return 'constructor'
    }
  },
  methods: {
    formatSelected (eavEntity) {
      return new PassportEntity({ eavEntity })
    },
    transformValue (valueItem) {
      return valueItem.eavEntity
    },
    findSelectedItem: item => data => item.id === data.id,
    findValueItem: item => data => item.id === data.eavEntity.id,
    handleConfirm () {
      this.handleInput(false)
    },
    handleInput (event) {
      this.$emit('update:dialog', event)
      this.$emit('done')
    }
  }
}
</script>

<style scoped>
  .wrapper-map-table, .map-table {
    height: 93%;
  }
  /deep/ .map-table .v-data-table {
    height: 100%;
  }
  /deep/ .map-table .v-data-table .v-data-table__wrapper {
    max-height: 87%;
  }
  .object-table /deep/ .v-data-table .v-data-table__wrapper {
    max-height: calc(100vh - 269px) !important
  }
</style>
